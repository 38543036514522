import "ag-grid-community/dist/styles/ag-grid.min.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";

import { _ } from "vue-underscore";

export default {
  name: "UserList",
  data() {
    return {
      teamId: null,
      departmentId: null,
      userList: [],
      departmentList: [],
      teamList: [],
      selected: "",
      searchText: "",
      showLoader: false,
      selectedId: 0,
      selectedRows: [],
      sortColumn: "name",
      sortDirection: "asc",
      selectedValue: "",
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
    };
  },
  mixins: [paginationMixin],
  components: { PaginationComponent },
  methods: {
    getNextPage(page) {
      this.$refs.paginate.selected = page;
      this.paginate.current_page = page;
      this.getAll();
    },

    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      queryString += parseInt(this.teamId) > 0 ? "&team_id=" + this.teamId : "";
      queryString +=
        parseInt(this.departmentId) > 0
          ? "&department_id=" + this.departmentId
          : "";

      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },
    search() {
      this.getAll();
    },
    //Get Team On Agent  Module
    getTeam() {
      const _vm = this;
      this.axios
        .get("/team-details")
        .then(function (response) {
          _vm.teamList = response.data.data;
        })
        .catch(function () {});
    },
    //Get Department On Agent  Module
    getDepartment() {
      const _vm = this;
      this.axios
        .get("/department-details", _vm.departmentList)
        .then(function (response) {
          _vm.departmentList = response.data.data;
        })
        .catch(function () {});
    },
    getAll() {
      const _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Send a GET request to the "/agent-index" endpoint with the query stringwa
      this.axios
        .get("/agent-index" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.userList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
  },
  mounted() {
    this.getAll();
    this.getTeam();
    this.getDepartment();
  },
};
